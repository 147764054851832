import { toggleDashMobileMenu } from '../../redux/slices/layoutSlice';
import { useToolkit, useDashboardRoutes } from '../../components';
import { MdClose } from 'react-icons/md';
import { LANDING_ROUTES } from 'utils/appData/appRoutes';
import { Drawer } from 'antd';
import Image from 'next/image';
import { GoDotFill } from 'react-icons/go';
type Props = {};

const DashboardMobileMenu = (props: Props) => {
  const { dispatch, useAppSelector, router, logout, Link } = useToolkit();
  const { dashboardRoutes } = useDashboardRoutes();

  const { isDashMenuOpen } = useAppSelector((state) => state.layout);

  const LogoutBtn = () => {
    return (
      <li
        onClick={() => {
          dispatch(toggleDashMobileMenu());
          logout();
        }}
        className="flect w-full py-3 pl-2 lg:pl-3 curp tracking-wide text-xs transition-all duration-200 hover:scale-95 text-dark-grey"
      >
        <Image
          width={24}
          height={24}
          priority
          src="/assets/icons/sidebar/logout.svg"
          alt="logout"
          className="mr-3 text-2xl"
        />
        <span className='ml-3 lg:ml-2.5'>Log out</span>
      </li>
    );
  };

  const returnHome = () => {
    dispatch(toggleDashMobileMenu());
    router.push(LANDING_ROUTES.PERSONAL_HOMEPAGE);
  };

  <div className="curp" onClick={returnHome}>
    <img src="/assets/landing/qwidLogo.svg" width={53} alt="logo" />
  </div>;

  const closeDrawer = () => dispatch(toggleDashMobileMenu());

  return (
    <Drawer
      title={null}
      placement="left"
      onClose={closeDrawer}
      closable={false}
      visible={isDashMenuOpen}
    >
      <div className="flex-btw mb-7 mt-5">
        <MdClose size="1.6rem" className="curp" onClick={closeDrawer} />
      </div>

      <div className="curp flexed mb-10 w-max mx-auto" onClick={returnHome}>
        <img src="/assets/landing/qwidLogo.svg" width={80} alt="logo" />
      </div>

      <div className="w-3/5 mx-auto">
        <ul className="flex flex-col space-y-4 md:space-y-5 lg:space-y-6 px-2 lg:px-3">
          {dashboardRoutes.map(({ title, path, iconReference }) => {
            const isActive =
              router.pathname.includes(path) || router.asPath.includes(path);
            return (
              <Link key={path} href={path}>
                <a
                  className={`sidebar-route flect w-full py-1 pl-2 lg:pl-3 curp tracking-wide hover:scale-95 hover:text-dark-grey transition-transform ${isActive ? 'text-qwid-blue-200 font-medium' : 'text-dark-grey'
                    }`}
                >
                  <img
                    src={`/assets/icons/sidebar/${iconReference}-${isActive ? 'active' : 'inactive'
                      }.svg`}
                    alt={title}
                    className="text-lg md:text-xl lg:text-xl"
                    width={25}
                  />
                  <span className={`text-[9px] mx-1.5 ${isActive ? 'text-qwid-blue-200' : 'text-qwid-gray-950'}`}>
                    <GoDotFill />
                  </span>
                  <span
                    onClick={() => {
                      dispatch(toggleDashMobileMenu());
                    }}
                    className={`sidebar-route flect w-full py-3 curp tracking-wide hover:scale-95 hover:text-dark-grey transition-transform ${isActive ? 'text-qwid-blue-200 font-medium' : 'text-dark-grey'
                      }`}>
                    {title}
                  </span>
                </a>
              </Link>
            );
          })}
          <LogoutBtn />
        </ul>
      </div>
    </Drawer>
  );
};

export default DashboardMobileMenu;
