
import React, { useState, useMemo, useEffect } from "react";
import QwidButton from 'components/misc/QwidButton';
import CustomInput from 'components/onboarding/formComponents/CustomInput';
import snsWebSdk from '@sumsub/websdk';
import { countryCodes } from "utils/appData/countryCodes";
import Image from 'next/image';
import { Form } from "antd";
import { AiFillInfoCircle } from 'react-icons/ai';
import moment from "moment";
import { HiOutlineArrowLeft } from 'react-icons/hi';
import useToolkit from "utils/hooks/misc/useToolkit";
import SumSubInitModal from "components/auth/SumSubInitModal";
interface EditProps {
  backFxn: () => void;
  userObj: any
}

const genderOptions: any = [
  { value: 'male', title: "Male" },
  { value: 'female', title: "Female" },
  { value: 'other', title: "Other" },

]
const EditProfile = ({ backFxn, userObj }: EditProps) => {
  const [form] = Form.useForm();
  const [isKycModalOpen, setIsKycModalOpen] = useState<boolean>(false)

  let mode: string = "identity";
  const accessToken = 'sbx:v8tY0wvqpZhKqERazDd5msLN.YR46ErgwHqpwo44TZJV8Wz4IHQzlGuNK'
  const { useAppSelector, dispatch, toastInfo, userEmail, axiosInstance } = useToolkit();
  function launchWebSdk(accessToken: string, userEmail: string, mode: string, metadata: any) {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getNewAccessToken(userEmail, mode, metadata))
      .withConf({
        lang: "en",
        email: userEmail,
        uiConf: {
          customCss: 'https://url.com/styles.css'
        }
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.stepCompleted', (payload) => {
        // listen for 'Application completed'
        // listen for applicantReviewed
      })
      .on("idCheck.onError", (error) => { })

      .onMessage((type, payload) => { })
      .build();
    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }

  const getNewAccessToken = async (userEmail: string, mode: string, metadata: any) => {
    try {
      const response = await axiosInstance.post(`sumsub/sdk-token/${userEmail}`, {
        mode,
        metadata,
      });
      const { token } = response.data.content.data;
      return token;
    } catch (error) {
      // console.error("Failed to fetch new access token:", error);
      return null;
    }
  };
  const nationalityOptions = useMemo(() => countryCodes.map(nationality => ({
    title: nationality.nationality,
    value: nationality.nationality,
    Id: nationality.code as any,
  })), []);
  const initialValues: any = {
    phone_number: userObj?.mobileCode + userObj?.mobile,
    first_name: userObj?.firstName,
    email_address: userObj?.email,
    last_name: userObj?.lastName,
    dob: moment(userObj?.dob, "DD-MM-YYYY"),
    gender: userObj?.sex

  };


  const employmentOptions: any = [
    { value: 'employed', title: 'Employed' },
    { value: 'unemployed', title: 'Unemployed' },
  ];

  const handleFormSubmit = (values: any) => {

    setIsKycModalOpen(true)
    const metadata = [
      { firstName: values?.first_name },
      { lastName: values?.last_name },
      { dob: values?.dob },
      { nationality: values?.nationality },
      { employmentStatus: values?.employment },
    ]

    setTimeout(() => {
      launchWebSdk(accessToken, userEmail, mode, metadata);
    }, 1500);
  };

  const handleButtonClick = () => {
    form.submit();
  };
  return (
    <div>
      <div
        onClick={backFxn}
        className="text-primary cursor-pointer flex gap-1 items-center"
      >
        <HiOutlineArrowLeft /> Back
      </div>
      <p className="font-semibold text-[16px] my-[2rem]">
        Edit personal Information
      </p>
      <Form

        className="onb-form-wrapper"
        //initial values go here
        initialValues={initialValues}
        onFinish={handleFormSubmit}
        form={form}
      >
        <div>
          <div className="onb-form-item my-9">
            <CustomInput label="First name " name="first_name" />
            <CustomInput label="Last name" name="last_name" />
          </div>
          <div className="onb-form-item my-9">
            <CustomInput
              label="Email address"
              name="email_address"
              isEmailValidated
              nonEditable
            />
            <CustomInput

              label="Date of birth"
              isDob
              isDatePicker
              name="dob"
            />

          </div>
          <div className="onb-form-item my-9">
            <CustomInput
              name="phone_number"
              label="Phone Number"


            />
            <CustomInput
              isSelector
              label="Gender"
              name="gender"
              options={genderOptions}


            />
          </div>
        </div>

      </Form>
      <div className="bg-[#FFFAF1] flex justify-between px-5 py-5 rounded-[8px]">
        <div className="flex flex-col gap-[0.6rem] font-medium">
          <div className='flex items-center gap-2'><AiFillInfoCircle className='text-[#F6A004]' />NOTE</div>
          <div>The documents you'll be providing are:</div>
          <li className="font-normal pl-2">
            ● &nbsp; Valid ID - International Passport, NIN, or Driver’s License
          </li>
          <div className='w-full mt-3 max-w-[16rem]'>
            <QwidButton onClick={handleButtonClick} isFull text={'Continue upload document'} />
          </div>
        </div>
        <Image
          width={120}
          height={120}
          src="/assets/common/uploadDoc.svg"
          alt="upload"
        />
      </div>
      <SumSubInitModal
        control={isKycModalOpen}
        onClose={() => setIsKycModalOpen(!isKycModalOpen)}
      />
    </div>
  );
};

export default EditProfile;
