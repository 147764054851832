import React from "react";

type Props = {
	condition: boolean;
	children: React.ReactNode;
};

const OptionalRender = ({ condition, children }: Props) => {
	return condition ? <>{children}</> : null;
};

export default OptionalRender;
