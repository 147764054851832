import "../styles/globals.css";
import "../styles/table-styles.css";
import type { AppProps } from "next/app";
import ProgressBar from "@badrap/bar-of-progress";
import { persistor, store } from "../src/redux/store";
import { motion, AnimatePresence } from "framer-motion";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import Router from "next/router";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";

import TagManager, { TagManagerArgs } from "react-gtm-module";
import React, { useEffect } from "react";
import Notification from "components/notification/Notification";
import ZohoWidget from "utils/hooks/misc/useZoho";

import { Toaster } from "sonner";
import { FaCircleCheck } from "react-icons/fa6";


const progress = new ProgressBar({
  size: 3,
  color: "#48BBED",
  className: "z-50",
  delay: 30,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const queryClient = new QueryClient();

function MyApp({ Component, pageProps, router }: AppProps) {
  const gtmID = process.env.NEXT_PUBLIC_GTAG_MGR_ID;
  const PIXEL_ID = process.env.NEXT_PUBLIC_FBOOK_PIXEL_ID;

  const isLocalEnv =
    process.env.NODE_ENV === "development" ||
    process.env.NEXT_PUBLIC_LBASE_URL?.includes("test");

  const tagManagerArgs: TagManagerArgs = {
    gtmId: gtmID || "",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(PIXEL_ID || ""); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
    // eslint-disable-next-line
  }, [router.events]);


  return (
    <ToastProvider autoDismiss autoDismissTimeout={3500} placement="top-center">
      <AnimatePresence>
        <div className="max-w-[2000px] mx-auto">
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {!isLocalEnv && <ZohoWidget />}
                <Component {...pageProps} />
                <CustomToastWrapper />
                <Notification />
              </PersistGate>
            </Provider>
          </QueryClientProvider>
        </div>
      </AnimatePresence>
    </ToastProvider>
  );
}

const CustomToastWrapper = () => {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        unstyled: true,
        classNames: {
          toast: 'flex items-start bg-qwid-dark-25 font-creato font-medium relative p-3 pl-4 rounded-lg',
          title: 'text-white ml-2 mb-0',
        },
      }}
      icons={{
        success: <DefaultIcon />,
        info: <DefaultIcon />,
        warning: <DefaultIcon />,
        error: <DefaultIcon />,
        loading: <DefaultIcon />,
      }}
    />
  );
}

const DefaultIcon = () => {
  return (
    <div className="!pt-1.5">
      <FaCircleCheck className='text-xl text-white' />
    </div>
  )
}

export default MyApp;
