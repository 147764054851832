import styled from "styled-components";
import { LANDING_ROUTES } from "utils/appData/appRoutes";
import { useToolkit } from "../../components";
import ZohoWidget from "utils/hooks/misc/useZoho";

type Props = {
	darkFooter?: boolean;
};

type FeatureProps = {
	header: string;
	isMobileNum?: boolean;
	children: {
		title: string;
		route?: string;
		phone?: string;
		mail?: string;
		onClick?: () => void;
	}[];
	extras?: SocialMediaLinkProps[];
};

const LandingFooter = ({ }: Props) => {
	const { router } = useToolkit();

	const addresses = [
		{
			title: "UK",
			subtitle: "30 Churchill Pl, London E14 5EU, UK.",
		},
		{
			title: "Lagos",
			subtitle:
				"LCA Building-1A Remi Olowude Street, 2nd Roundabout, Lekki-Epe Expressway, Lagos, Nigeria.",
		},
		{
			title: "Canada",
			subtitle: "Address: 7111 Syntex Drive, 3rd Floor, Mississauga, Ontario L5N 8C3 Canada.",
		},
	];

	const featureItems: FeatureProps[] = [
		{
			header: "Features",
			children: [
				{ title: "Direct Pay-in", route: LANDING_ROUTES.BUSINESS_HOMEPAGE },
				{ title: "Checkout", route: LANDING_ROUTES.BUSINESS_HOMEPAGE },
				{ title: "Virtual Accounts", route: LANDING_ROUTES.BUSINESS_HOMEPAGE },
				{ title: "Remittance", route: LANDING_ROUTES.BUSINESS_HOMEPAGE },
				{ title: "Withdrawals", route: LANDING_ROUTES.BUSINESS_HOMEPAGE },
			],
		},
		{
			header: "Company",
			children: [
				{ title: "FAQs", route: "/faqs" },
				{ title: "Contact Us", route: LANDING_ROUTES.BUSINESS_HOMEPAGE },
				{ title: "Blog", route: LANDING_ROUTES.BLOG_HOME_PAGE },
			],
		},
		{
			header: "Legal",

			children: [
				// {
				// 	title: "Contact Us",
				// 	onClick: () => {
				// 		if(tawkMessengerRef) {
				// 			tawkMessengerRef?.current?.toggle()
				// 		}
				// 	}
				// },
				{ title: "Terms of service", route: "/terms" },
				{ title: "Privacy notice", route: "/privacy-policy" },
			],
		},
		{
			header: "Contact us",
			isMobileNum: true,
			children: [
				{ title: "support@qwid.io", mail: "support@qwid.io" },
				{ title: "US: +1 (888) 2072485", phone: "+1 (888) 2072485" },
				{ title: "UK: +44 (800) 1026864", phone: "+44 (800) 1026864" },
				{ title: "Canada: +1 (877) 2025103", phone: "+1 (877) 2025103" },
			],
			extras: [
				{
					url: "https://twitter.com/Qwidapp?t=eBGx_LM5NUkhhsYkza9OrA&s=09",
					iconPath: "/assets/landing/footer-twitter.svg",
				},
				{
					url: "https://www.facebook.com/Qwid.io?mibextid=ZbWKwL",
					iconPath: "/assets/landing/footer-facebook.svg",
				},
				{
					url: "https://www.linkedin.com/company/qwid-appf/",
					iconPath: "/assets/landing/footer-linkedin.svg",
				},
				{
					url: "https://instagram.com/qwidapp?igshid=MzRlODBiNWFlZA==",
					iconPath: "/assets/landing/footer-instagram.svg",
				},
			],
		},
	];

	const isLocalEnv =
		process.env.NODE_ENV === "development" ||
		process.env.NEXT_PUBLIC_LBASE_URL?.includes("test");

	return (
		<div className="side-pad">
			<footer className="h-pad text-black">
				{/* {!isLocalEnv && <Widget />} */}
				<ZohoWidget />
				<div className="overflow-hidden flex items-center justify-center lg:justify-start flex-wrap lg:flex-nowrap lg:space-x-8 relative h-[400px]">
					<Wrapper className="absolute w-full h-full -right-14 z-5 " />
					<WrapperSmall className="absolute w-full h-full -right-[800px] -top-[40px] z-5" />
					{/* feature routers */}
					<div className="w-full flex items-start  lg:justify-between flex-wrap lg:flex-nowrap mt-6  lg:mt-0 z-10">
						{featureItems.map(({ header, isMobileNum, children, extras }) => (
							<div className="w-2/4  lg:w-1/4  lg:mb-0 " key={header}>
								<p className="lg:mb-4 mb-3 smb lg:text-xl">{header}</p>
								<div className="flex flex-col t4  ">
									{children.map(({ title, route, phone, mail, onClick }) =>
										isMobileNum ? (
											mail ? (
												<a
													key={phone}
													href={`mailto:${mail}`}
													className="footer-link">
													{title}
												</a>
											) : (
												<a
													key={phone}
													href={`tel:${phone}`}
													className="footer-link">
													{title}
												</a>
											)
										) : (
											<p
												onClick={() => {
													if (onClick) {
														onClick();
													} else if (route) {
														router.push(route);
													}
												}}
												key={title}
												className="footer-link">
												{title}
											</p>
										)
									)}
								</div>
								<div className="flex items-center mt-2 justify-start lg:pt-5">
									{extras?.map(({ iconPath, url }: SocialMediaLinkProps) => (
										<span key={url} className="mr-6 lg:mr-10">
											<a href={url} target="_blank" rel="noreferrer">
												<img src={iconPath} alt="social-media-icon" />
											</a>
										</span>
									))}
								</div>
							</div>
						))}
					</div>
				</div>

				<hr className="w-full h-[0.5px] mt-5 lg:mt-14 bg-[#8c9099]" />
			</footer>

			<div>
				<div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-between">
					<div className="w-full lg:w-1/5 mb-7 lg:mb-0">
						<img
							src="/assets/landing/qwidLogo.svg"
							alt="logo"
							className="mb-2 lg:mb-7"
						/>
					</div>
					<div className="w-full lg:w-5/6 lg:ml-16 flex flex-wrap lg:flex-nowrap items-start justify-center lg:justify-between">
						{addresses.map(({ title, subtitle }) => (
							<div className="mb-4 lg:mb-0 w-full lg:w-[30%]" key={title}>
								<p className="mb-2 font-medium lg:text-lg">{title}</p>
								<p className="text-sm">{subtitle}</p>
							</div>
						))}
					</div>
				</div>

				<div className="flex items-center justify-center lg:justify-end lg:pl-24 lg:pt-10 pb-10 lg:pb-12">
					<div className="w-full lg:w-5/6 flex items-center justify-center lg:justify-between flex-wrap">
						{/* <div className="w-full lg:w-2/4">
							<div className="mb-7 lg:mb-0">
								<p className="mb-2 font-medium lg:text-lg">Lithuania</p>
								<p className="text-sm">
									Vilnius, Perkunkiemio g. 13-91, Lithuania
								</p>
							</div>
						</div> */}

						<div className="w-full lg:w-2/4 flex items-center justify-center lg:justify-end">
							<a
								href="https://play.google.com/store/apps/details?id=io.blinqpay.qwid"
								target="_blank"
								rel="noreferrer"
								className="mr-3">
								<img
									width={150}
									src="/assets/business/footer-gplay.svg"
									alt="store-btn"
									className="store-btn"
								/>
							</a>

							<a
								href="https://apps.apple.com/us/app/qwid/id6444584444"
								target="_blank"
								rel="noreferrer"
								className="mr-3">
								<img
									width={150}
									src="/assets/business/footer-app-store.svg"
									alt="store-btn"
									className="store-btn"
								/>
							</a>
						</div>
					</div>
				</div>

				<div className="w-full  mx-auto tcent pb-8 lg:pb-14">
					<p className="text-sm mb-2">Qwid, a product of Blinqpay</p>
					<p className="mb-2">
						Licensed by Financial Transactions and Reports Analysis Centre of{" "}
						Canada (FINTRAC) as an{" "}
						<a
							href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3137303138362c227072696d617279536561726368223a7b226f72674e616d65223a22626c696e71706179222c2273656172636854797065223a317d7d/"
							target="_blank"
							rel="noreferrer">
							<span className="pry-text underline">MSB</span>
						</a>
						&nbsp;(Money Services Business) in Canada.
					</p>
					<p className="mb-7">Incorporation number: 1583123 -7, Regulated by The Financial Conduct Authority of the UK (FCA) as a PSD agent.</p>

					<p className="mb-0 mt-4 text-xs">
						This site is protected by copyright &copy; Qwid Inc.{" "}
						{new Date().getFullYear()}. &nbsp; All Rights Reserved.
					</p>
				</div>
			</div>
		</div>
	);
};

export default LandingFooter;

const WrapperSmall = styled.div`
	transform: scale(0.8);
	background-image: url("/assets/business/world-map.png");
	background-repeat: no-repeat;
`;

const Wrapper = styled.div`
	background-image: url("/assets/business/world-map.png");
	background-repeat: no-repeat;
`;
