import { useToolkit } from '../../components';
import { MdAdd, MdSend } from 'react-icons/md';
import { BsArrowDownLeftSquareFill } from 'react-icons/bs';
import { RiSwapFill } from 'react-icons/ri';
import {
  setTopupCurrency,
  setWalletFlag,
  toggleSendOptions,
  toggleTopupOptions,
  toggleWithdrawalModal
} from 'redux/slices/dashboardSlice';
import { motion } from 'framer-motion';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import { KYCStatus } from '../../custom-types';

type Props = {
  cur: string;
  flag: string;
  balance: string;
  idx: any;
};

const UserWalletCard = ({ cur, flag, balance, idx }: Props) => {
  const { isMobile, dispatch, router, getSymbol, useAppSelector, customToast, isPersonalAccount } =
    useToolkit();
  const userState = useAppSelector((state) => state.user);
  const actionItems = [
    {
      Icon: MdAdd,
      title: 'Top-up',
      onClick: () => {
        if (userState?.kycStatus !== KYCStatus.COMPLETED) {
          customToast({
            message: `You cannot perform this action unless your ${isPersonalAccount ? 'KYC' : 'KYB'
              } status is approved`,
            type: 'info',
            timeout: 12000
          });
        } else {
          dispatch(setWalletFlag(cur));
          dispatch(setTopupCurrency(cur));
          dispatch(toggleTopupOptions());
        }
      }
    },
    {
      Icon: MdSend,
      title: 'Send',
      onClick: () => {
        if (userState?.kycStatus !== KYCStatus.COMPLETED) {
          customToast({
            message: `You cannot perform this action unless your ${isPersonalAccount ? 'KYC' : 'KYB'
              } status is approved`,
            type: 'info',
            timeout: 12000
          });
        } else dispatch(toggleSendOptions());
      }
    },
    {
      Icon: BsArrowDownLeftSquareFill,
      title: 'Withdraw',
      onClick: () => {
        if (userState?.kycStatus !== KYCStatus.COMPLETED) {
          customToast({
            message: `You cannot perform this action unless your ${isPersonalAccount ? 'KYC' : 'KYB'
              } status is approved`,
            type: 'info',
            timeout: 12000
          });
        } else dispatch(toggleWithdrawalModal());
      }
    },
    {
      Icon: RiSwapFill,
      isLarge: true,
      title: 'Convert funds',
      onClick: () => router.push(DASHBOARD_ROUTES.CONVERSIONS)
    }
  ];

  const formatBalance = (balance: any) => {
    const splitValue = balance?.toString()?.split('.', 2);

    if (!splitValue[1]) return parseFloat(`${splitValue[0]}.00`)?.toLocaleString();

    return parseFloat(`${splitValue[0]}.${splitValue[1]?.substring(0, 2)}`)?.toLocaleString();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 120,
          duration: 0.3,
          delay: idx * 0.12
        }
      }}
      viewport={{ once: true }}
      style={{ minWidth: isMobile ? '85%' : '35%' }}
      className='mr-7 w-4/5 md:w-full mxs-auto bg-[#194153] rounded-md py-6 pb-1 pl-4 lg:pl-6 twite'>
      <div className='flex items-center justify-between'>
        <div className='flex flex-col'>
          <div className='flect'>
            <img
              src={flag}
              alt={flag}
              className='rounded-full object-cover w-7 h-7 lg:w-9 lg:h-9 mr-3'
            />
            <p className='mb-0 med twite t4 lg:t6 nowrap'>
              <span className='uppercase'>{cur}</span> Balance
            </p>
          </div>
          <p className='mb-4 mt-5 text-[#DEE0E2] text-xs lg:t4 twide'>Balance Available</p>
          <p className='mb-1 lg:text-xl smb twide'>
            {getSymbol(cur)}
            &nbsp;
            {formatBalance(balance)}
          </p>
        </div>

        {/* right */}
        <div className='twite pr-3 lg:pr-6'>
          {actionItems.map(({ Icon, title, onClick, isLarge }) => (
            <div
              onClick={onClick}
              className={`flect curp hover:scale-105 trall mb-4 ${isLarge && ''}`}
              key={title}>
              <Icon
                size={isMobile && isLarge ? '1.4rem' : isLarge ? '1.7rem' : '1.2rem'}
                className={isLarge ? 'rotate-45' : ''}
              />{' '}
              <p className='mb-0 ml-2 text-xs lg:text-sm'>{title}</p>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default UserWalletCard;
