import { IconType } from 'react-icons';
import { IoSend } from 'react-icons/io5';
import {
  toggleKycMagnifier,
  addFundsData,
  toggleSelectedWallets,
  toggleWalletCreationModal,
} from 'redux/slices/dashboardSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import { GoDotFill } from 'react-icons/go';
import {
  OptionalRender,
  useTransactions,
  useToolkit,
  useDashboard,
} from 'components';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import useVirtualAccounts from 'utils/hooks/virtuals/useVirtualAccounts';
import {
  clearInternalTransferDetails,
  closeNewRecipientState,
  setInternalCurrentPage,
} from 'redux/slices/transactionsSlice';
import { KYCStatus } from 'custom-types';
import { IoMdAdd } from 'react-icons/io';
import React from 'react';
import Image from 'next/image';

type WalletDropdownProps = {
  options: WalletProps[];
  toggleDropdown: () => void;
  formatBalanceWithoutRoundUp: (balance: any) => string;
  selected: UserWallet;
  dropdownShown: boolean;
};

type ShortcutProps = {
  title: string;
  imgPath: string;
  Icon?: IconType;
  onClick: () => void;
};

type Props = {
  loading: boolean;
  // userWallets: WalletProps[];
  selectedWallet: UserWallet;
  kycState: any;
};

const WalletActionsCard = ({ selectedWallet, kycState }: Props) => {
  const {
    getCurrencySymbol,
    useAppSelector,
    dispatch,
    useEffect,
    useState,
    router,
  } = useToolkit();
  const { formatBalanceWithoutRoundUp } = useTransactions();
  const { userVirtualAccts, userWallets } = useAppSelector(
    (state) => state.dashboard
  );
  const { accLoading, getVirtualAccounts } = useDashboard();
  const [dropdownShown, setDropdownShown] = useState(false);
  const { useRequestVirtualAcct } = useVirtualAccounts();
  const activeVirtualAccount = userVirtualAccts?.find(
    (item: any) => item?.cur === selectedWallet?.cur
  );
  const { isLoading, mutateAsync: requestVirtualAcct } = useRequestVirtualAcct;

  const toggleDropdown = () => setDropdownShown(!dropdownShown);
  const noAccountForWallet =
    accLoading === false &&
    userVirtualAccts?.length > 0 &&
    (activeVirtualAccount?.bankName?.length < 1 ||
      activeVirtualAccount?.accountNo?.length < 1);
  const { payload } = useAppSelector(
    (state) => state.dashboard.addFundsDetails
  );

  const virtualAccountValid =
    accLoading === false &&
    activeVirtualAccount !== undefined &&
    activeVirtualAccount?.bankName?.length > 0 &&
    activeVirtualAccount?.accountNo?.length > 0;

  const handleSend = () => {
    if (kycState === KYCStatus.COMPLETED) {
      dispatch(clearInternalTransferDetails());
      dispatch(setInternalCurrentPage(1));
      dispatch(closeNewRecipientState());
      router.push(DASHBOARD_ROUTES.SEND);
    } else {
      dispatch(toggleKycMagnifier(true));
      setTimeout(() => dispatch(toggleKycMagnifier(false)), 1000);
    }
  };
  const selectedWallets = selectedWallet?.cur || router.query?.cur;

  const handleAddFunds = () => {
    // if(activeVirtualAccount){
    // 	router.push(DASHBOARD_ROUTES.ADD_FUND);
    // 	dispatch(addFundsData(activeVirtualAccount))
    // }else{
    // 	toastInfo("Virtual account not found")
    // }
    if (kycState != KYCStatus.COMPLETED) {
      dispatch(toggleKycMagnifier(true));
      setTimeout(() => dispatch(toggleKycMagnifier(false)), 1000);
      return
    }
    dispatch(
      addFundsData({
        ...(activeVirtualAccount || {}),
        selectedWallet: selectedWallet?.cur,
      })
    );
    if (selectedWallets && !payload?.accountNo) {
      requestVirtualAcct(selectedWallets as any)
        .then((res) => {
          dispatch(addFundsData({ ...res, selectedWallets }));
        })
        .catch(() => { });
    }

    // router.push(DASHBOARD_ROUTES.ADD_FUND);
    router.push(`${DASHBOARD_ROUTES.ADD_FUND}?cur=${selectedWallet?.cur}`);
  };

  const shortcuts: ShortcutProps[] = [
    {
      title: 'Add funds',
      imgPath: '/assets/icons/wallet/wallet-add-white.svg',
      onClick: handleAddFunds,
    },
    {
      title: 'Send',
      Icon: IoSend,
      imgPath: '/assets/icons/wallet/send-white.svg',
      onClick: handleSend,
    },
  ];

  useEffect(() => {
    getVirtualAccounts();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      onClick={() => setDropdownShown(false)}
      className=" bg-white p-3 lg:p-7 rounded-xl lg:min-h-[250px] shadow-sm border border-[#B7D2FB]"
    >
      <div className="flex-btw my-auto">
        <div className="flex gap-3 items-start w-max">
          <div className=''>
            <Image
              width={40}
              height={40}
              objectFit='cover'
              priority
              src={selectedWallet.flag}
              alt={selectedWallet.flag}
              className="rounded-full object-cover flex w-full mr-3 max-w-[3rem]"
            />
          </div>

          <div>
            <div className="text-[#090909] text-xs  md:text-base font-semibold mb-1">
              {selectedWallet.cur} Wallet
            </div>

            <OptionalRender condition={accLoading}>
              <p className="mb-0 text-[#053202] text-[9.5px] md:text-xs font-medium">
                Fetching virtual accounts...
              </p>
            </OptionalRender>

            <OptionalRender condition={noAccountForWallet}>
              <p className="mb-0 text-[#053202] text-[9.5px] md:text-xs font-medium">
                Account details unavailable
              </p>
            </OptionalRender>

            <OptionalRender condition={virtualAccountValid}>
              <p className="mb-0 text-[#053202] text-[10px] md:text-xs font-medium flex items-center">
                {activeVirtualAccount?.bankName}
                <span className="text-[#9ABB98] text-[8px] px-[1px] lg:px-1">
                  <GoDotFill />
                </span>
                {activeVirtualAccount?.accountNo}
              </p>
            </OptionalRender>
          </div>
        </div>

        <WalletDropdown
          options={userWallets}
          selected={selectedWallet}
          dropdownShown={dropdownShown}
          toggleDropdown={toggleDropdown}
          formatBalanceWithoutRoundUp={formatBalanceWithoutRoundUp}
        />
      </div>

      <div className="mb-7 mt-3">
        <div className="text-[#888888] text-xs mb-1.5">Available Balance</div>

        <div className="text-[#090909] text-sm md:text-xl lg:text-3xl font-semibold">
          {Number(selectedWallet?.balance) < 0 ||
            selectedWallet?.balance === undefined ? (
            '-'
          ) : (
            <>
              {getCurrencySymbol(selectedWallet?.cur)}
              {formatBalanceWithoutRoundUp(selectedWallet.balance)}
            </>
          )}
        </div>
      </div>

      <div className="w-full lg:w-3/5 flex items-center justify-center flex-col md:flex-row md:justify-start md:space-x-7">
        {shortcuts.map(({ title, imgPath, onClick }) => (
          <div
            onClick={onClick}
            key={title}
            className="w-full flex items-center justify-center lg:justify-start lg:w-max bg-dark-blue text-white mb-3 lg:mb-0 rounded-md px-2 lg:px-2.5 py-2.5 lg:py-1 curp"
          >
            <img className="w-4 md:w-6" src={imgPath} alt={title} />
            <span className="ml-2 nowrap text-xs">{title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const WalletDropdown = ({
  options,
  selected,
  dropdownShown,
  toggleDropdown,
  formatBalanceWithoutRoundUp,
}: WalletDropdownProps) => {
  const { getCurrencySymbol, dispatch, useEffect } = useToolkit();
  const { fetchUserWallets, hasPendingWallets } = useDashboard();

  const handleClick = (e: any) => {
    e.stopPropagation();
    toggleDropdown();
  };

  const handleSelect = (cur: string) => {
    dispatch(toggleSelectedWallets(cur));
    toggleDropdown();
  };

  useEffect(() => {
    fetchUserWallets();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      onClick={handleClick}
      className="border border-[#194153] rounded-[4px] box-content relative cursor-pointer p-2"
    >
      <div className="flexed space-x-2">
        <Image
          width={16}
          height={16}
          src={selected.flag}
          alt={selected.flag}
          className="object-contain w-5 h-5 mr-1"
        />
        <span className="text-[#252D3E] text-xs font-medium ">
          {selected.cur}
        </span>
        <CaretDownOutlined className="flex items-center self-center hover:text-[#252D3E]" />
      </div>

      <div
        className={`absolute -right-1 top-10 w-[200px] lg:w-[250px] p-3 bg-white rounded-md shadow-lg transition-all duration-300 ${dropdownShown ? 'opacity-100 inline-block' : 'hidden opacity-0'
          }`}
      >
        {hasPendingWallets && (
          <div className="my-4 w-full flexed">
            <button
              onClick={() => dispatch(toggleWalletCreationModal())}
              className="px-4 lg:px-7 border border-dark-blue text-dark-blue flexed rounded-full"
            >
              <IoMdAdd />
              &nbsp;Add new wallet
            </button>
          </div>
        )}

        {options?.map(({ cur, flag, balance }) => {
          const isActive = cur === selected?.cur;
          return (
            <div
              onClick={() => handleSelect(cur)}
              key={cur}
              className={`flex-btw items-start w-full p-2 rounded-md hover:bg-gray-50 transition-colors ${isActive ? 'cursor-not-allowed bg-gray-100' : 'cursor-pointer'
                }`}
            >
              <div className="flex items-start">
                <div className="w-4 h-4 rounded-full flexed overflow-hidden mr-2">
                  <Image
                    width={24}
                    height={24}
                    src={flag}
                    alt={flag}
                    className="object-cover h-full w-full"
                  />
                </div>

                <p className="mb-0 text-[10px] lg:text-xs text-[#192A3A] font-medium">
                  {cur} wallet
                </p>
              </div>
              <div className="text-[#62666E] text-[8px] lg:text-xs">
                <p className="mb-0 text-[#62666E] text-right">Balance</p>
                <p
                  className={`mb-1 text-[#192A3A] text-xs lg:text-sm font-medium ${Number(balance) < 1 && ''
                    }`}
                >
                  {getCurrencySymbol(cur)}
                  {formatBalanceWithoutRoundUp(balance)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WalletActionsCard;
