import { RiApps2Fill } from "react-icons/ri";
import { BsGraphUp } from "react-icons/bs";
import { RiSettings4Line } from "react-icons/ri";
import { DASHBOARD_ROUTES, MERCHANT_ROUTES } from "utils/appData/appRoutes";

type ChildRouteProps = {
  title: string;
  path: string;
};

const useDashboardRoutes = () => {
  const dashboardRoutes: RouteProps[] = [
    {
      title: "Dashboard",
      path: DASHBOARD_ROUTES.DASHBOARD,
      Icon: RiApps2Fill,
      iconReference: "dashboard",
    },
    {
      title: "Transactions",
      path: DASHBOARD_ROUTES.TRANSACTIONS,
      Icon: BsGraphUp,
      iconReference: "transactions",
    },
    {
      title: "Recipients",
      path: DASHBOARD_ROUTES.RECIPIENTS,
      Icon: BsGraphUp,
      iconReference: "recipients",
    },
    {
      title: "Trade",
      path: DASHBOARD_ROUTES.TRADE,
      Icon: BsGraphUp,
      iconReference: "trade",
    },
    {
      title: "Refer & Earn",
      path: DASHBOARD_ROUTES.REFERRAL,
      Icon: BsGraphUp,
      iconReference: "gift",
    },
    // {
    //   title: "Profile",
    //   path: DASHBOARD_ROUTES.REFERRAL,
    //   Icon: BsGraphUp,
    //   iconReference: "gift",
    // },
    {
      title: "Settings",
      path: DASHBOARD_ROUTES.SETTINGS,
      Icon: RiSettings4Line,
      iconReference: "settings",
    },
    // {
    // 	title: "Merchant",
    // 	path: MERCHANT_ROUTES.MERCHANT_HOMEPAGE,
    // 	Icon: BsSoundwave,
    // 	isDropdown: true,
    // 	childRoutes: [
    // 		{
    // 			title: "Business One",
    // 			path: `${MERCHANT_ROUTES.MERCHANT_HOMEPAGE}/${Math.random()}`,
    // 		},
    // 		{
    // 			title: "Business Two",
    // 			path: `${MERCHANT_ROUTES.MERCHANT_HOMEPAGE}/${Math.random()}`,
    // 		},
    // 		{ title: "Settings", path: MERCHANT_ROUTES.MERCHANT_SETTINGS },
    // 	],
    // },
    // {
    // 	title: "Virtual Cards",
    // 	path: DASHBOARD_ROUTES.VIRTUAL_CARDS_HOME,
    // 	Icon: AiFillCreditCard,
    // },
    // {
    // 	title: "Beneficiaries",
    // 	path: DASHBOARD_ROUTES.BENEFICIARIES,
    // 	Icon: ImUsers,
    // },
  ];

  return { dashboardRoutes };
};

export default useDashboardRoutes;
