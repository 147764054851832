import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CryptoRateProps } from 'utils/hooks/trade/useCryptoProps';



type cryptoState = {
   selectedCryptoAsset: any;
   isBuyCryptoOpen: boolean;
   isSellCryptoOpen: boolean;
   isSendCryptoOpen: boolean;
   isReceiveCryptoOpen: boolean;
   ratesCopy: CryptoRateProps | null;
}

const initialState: cryptoState = {
   selectedCryptoAsset: null,
   isBuyCryptoOpen: false,
   isSellCryptoOpen: false,
   isSendCryptoOpen: false,
   isReceiveCryptoOpen: false,
   ratesCopy: null,
}

const cryptoSlice = createSlice({
   name: 'crypto',
   initialState,
   reducers: {

      selectCryptoAsset: (state, { payload }: PayloadAction<any>) => {
         return {
            ...state,
            selectedCryptoAsset: payload,
         };
      },

      toggleBuyCryptoModal: (state) => {
         return {
            ...state,
            isBuyCryptoOpen: !state.isBuyCryptoOpen,
         };
      },

      toggleSellCryptoModal: (state) => {
         return {
            ...state,
            isSellCryptoOpen: !state.isSellCryptoOpen,
         };
      },

      toggleSendCryptoModal: (state) => {
         return {
            ...state,
            isSendCryptoOpen: !state.isSendCryptoOpen,
         };
      },

      toggleReceiveCryptoModal: (state) => {
         return {
            ...state,
            isReceiveCryptoOpen: !state.isReceiveCryptoOpen,
         };
      },

      updateCryptoRate: (state, { payload }: PayloadAction<CryptoRateProps>) => {
         return {
            ...state,
            ratesCopy: payload,
         };
      },

      resetCryptoParams: (state) => {
         return {
            ...state,
            isBuyCryptoOpen: false,
            isSellCryptoOpen: false,
            isSendCryptoOpen: false,
            isReceiveCryptoOpen: false,
            ratesCopy: null,
         };
      },
   }
});

export const {
   selectCryptoAsset,
   toggleBuyCryptoModal,
   toggleSellCryptoModal,
   toggleSendCryptoModal,
   toggleReceiveCryptoModal,
   updateCryptoRate,
   resetCryptoParams,
} = cryptoSlice.actions

export default cryptoSlice.reducer