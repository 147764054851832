import { clearInternalTransferDetails, closeNewRecipientState, openNewRecipientState, setInternalCurrentPage, setInternalTransferDetails, setIsNewFromBenefs, setIsSendFromBenefs, setTransitionDirection } from "redux/slices/transactionsSlice"
import useBeneficiary from "../dashboard/useBeneficiary"
import useToolkit from "../misc/useToolkit"
import { closePinAuth } from "redux/slices/dashboardSlice"
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes"

const useInternalSendFlow = () => {
  const { useAppSelector, dispatch, router, getSymbol } = useToolkit()
  const { initQwidTransfer, trfLoading: handlingTransaction } = useBeneficiary()

  const { internalTransfer, } = useAppSelector((state) => state.transactions)
  const internalTransferDetails = internalTransfer.internalTransferDetails
  const internalCurrentpage = internalTransfer.internalCurrentpage
  const newRecipientstate = internalTransfer.newRecipientstate
  const isSendFromBenefs = internalTransfer.isSendFromBenefs
  const isNewFromBenefs = internalTransfer.isNewFromBenefs
  const transitionDirection = internalTransfer.transitionDirection

  const formatCurrencyBalance = (currencyCode: any, balance: any) => {
    let symbol;
    switch (currencyCode) {
      case 'EUR':
        symbol = '€';
        break;
      case 'USD':
        symbol = '$';
        break;
      case 'GBP':
        symbol = '£';
        break;
      case 'NGN':
        symbol = '₦';
        break;
      case 'BRL':
        symbol = 'R$';
        break;
      case 'TRY':
        symbol = '₺';
        break;
      case 'CAD':
        symbol = 'C$';
        break;
      case 'CNY':
        symbol = '¥';
        break;
      default:
        // Default to USD if an unsupported currency code is provided
        currencyCode = 'USD';
        symbol = '$';
    }

    const formattedBalance = (Math.floor(balance * 100) / 100).toFixed(2);
    return `${symbol}${formattedBalance}`;
  }

  function getCurrencySymbol(isoCode: string): string | null {
    return getSymbol(isoCode)
  }
  const handleTransaction = async (pin?: string) => {
    dispatch(setTransitionDirection('right'))
    const payload = {
      fromCur: internalTransferDetails.fromCur,
      toCur: internalTransferDetails.toCur,
      fromAmount: internalTransferDetails.fromAmountRaw,
      receiver: internalTransferDetails.recipient?.rcpId,
      is_beneficiary: internalTransferDetails.savebenef ? 'yes' : null,
      pin: internalTransferDetails.pin || pin
    }
    await initQwidTransfer(payload)
  }

  const incrementStep = () => {
    dispatch(setTransitionDirection('right'))
    dispatch(setInternalCurrentPage(internalCurrentpage + 1))
  }
  const decrementStep = () => {
    dispatch(setTransitionDirection('left'))
    dispatch(setInternalCurrentPage(internalCurrentpage - 1))
  }

  const setPage = (page: number) => {
    dispatch(setTransitionDirection('left'))
    dispatch(setInternalCurrentPage(page))
  }

  const allDone = async () => {
    if (isNewFromBenefs || isSendFromBenefs) {
      await router.push(DASHBOARD_ROUTES.RECIPIENTS)
    }
    else {
      await router.push(DASHBOARD_ROUTES.DASHBOARD)
    }

    setTimeout(() => {
      dispatch(closePinAuth())
      dispatch(clearInternalTransferDetails())
      dispatch(setInternalCurrentPage(1))
      dispatch(closeNewRecipientState())
      dispatch(setIsNewFromBenefs(false))
      dispatch(setIsSendFromBenefs(false))
    }, 1500)


  }
  const handleSendFromRecipient = (recipient: QwidRcpDetails) => {
    dispatch(clearInternalTransferDetails())
    const payload: InternalTransferProps = {
      recipient: recipient
    }
    dispatch(setIsSendFromBenefs(true))
    dispatch(setInternalTransferDetails(payload))
    dispatch(setInternalCurrentPage(2))
    router.push(DASHBOARD_ROUTES.SEND_INTERNAL)
  }

  const handleAddRcpFromRecipient = () => {
    dispatch(clearInternalTransferDetails())
    dispatch(setInternalCurrentPage(1))
    dispatch(setIsNewFromBenefs(true))
    dispatch(openNewRecipientState())
    router.push(DASHBOARD_ROUTES.SEND_INTERNAL)
  }

  return {
    formatCurrencyBalance,
    getCurrencySymbol,
    internalTransferDetails,
    newRecipientstate,
    internalCurrentpage,
    handleTransaction,
    handlingTransaction,
    incrementStep,
    decrementStep,
    setPage,
    allDone,
    handleSendFromRecipient,
    handleAddRcpFromRecipient,
    isSendFromBenefs,
    isNewFromBenefs,
    transitionDirection

  }
}

export default useInternalSendFlow